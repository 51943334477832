// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

/*
* Base structure
*/

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Lato', sans-serif;
}

a {
	color: #00bee9;

	&:focus, &:hover {
		color: #e61638;
	}
}

.logo {
	font-family: 'Oranienbaum', serif;
	font-size: 50px;
	line-height: 1.2;

	.name {
		display: none;
	}
	.year {
		color: #e61638;
	}

	@media (min-width: 768px) and (max-width: 991px) {
		font-size: 46px;
	}
}

.tagline {
	font-size: 12px;
	line-height: 1.2;

	@media (max-width: 767px) {
		clear: both;
		float: left;
		padding: 0 15px;
	}
}


nav {
	color: rgb(255, 255, 255);
	font-size: 14px;

	ul {
		text-transform: uppercase;
	}
	a {
		color: #fff;
	}

	.nav>li {
		>a:focus, >a:hover {
			background: rgba(0,0,0,0.4);
		}
	}
	&.navbar {
		border: none;
		padding: 42px 0 0;
	}
}
.navbar-toggle .icon-bar {
	background: #fff;
}
.navbar-brand {
	height: auto;
	padding: 15px 15px 0;

	@media (max-width: 767px) {
		padding: 0 15px;
	}
}
.hero {
	background: url(/images/black.png), #000 url(/images/hero.jpg) center 0 no-repeat;
	color: #fff;
	margin: 10px 0;
	min-height: 758px;
	position: relative;

	h1 {
		margin-top: 250px;

		@media (max-width: 767px) {
			font-size: 30px;
		}
	}
	h2 {
		margin-top: 250px;

		span {
			color: #00a6d5;
			font-size: .85em
		}
	}
}
.about {
	padding: 0 0 54px;
}
.instagram-img {
	margin: 16px 0;
}
.projects {
	.row {
		margin: 0 0 16px;

		@media (max-width: 767px) {
			margin: 0;
		}
	}
	.img-responsive {
		@media (max-width: 767px) {
			margin: 0 0 16px;
		}
	}
}
.download {
	a {
		margin: 0 0 0 4px;
	}
}

@media (max-width: 767px) {
	.container>.navbar-collapse {
		position: relative;
		top: 50px;
	}
	.logo {
		font-size: 42px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {

}

.social {
	bottom: 25px;
	position: absolute;

	.list-inline {
		margin-left: -17px;
	}
	li {
		font-size: 25px;

		&:nth-of-type(even) a:hover {
			color: #00bee9;
		}
	}
	a {
		color: #fff;
		padding: 12px;

		&:hover {
			color: #e31c3d;
		}

		@media (max-width: 767px) {
			padding: 4px;
		}
	}
	@media (max-width: 767px) {
		bottom: 5px;
	}
}

main {
	padding: 40px 0;

	p {
		font-size: 16px;
		line-height: 1.8;
		margin: 0 0 16px;

		&.date {
			font-size: 14px;
			margin: 0;
		}
		&.title {
			font-weight: 800;
			margin: 0;
		}
		&.location {
			font-style: italic;
		}
		&.description {
			font-size: 14px;
		}
	}
}

.photos {
	background: #00a5d4;
	color: #fff;
}

footer {
	background: #9a1919;
	color: #fff;
	font-size: 14px;
	height: 210px;
	line-height: 1.2;
	padding: 50px;
	text-align: center;

	p {
		font-size: 12px;
	}
}



.btn-outline {
	background: transparent;
	border: 3px solid rgb(227, 28, 61);
	border-radius: 10px;
	color: rgb(227, 28, 61);
	font-size: 22px;
	text-transform: uppercase;
	padding: 17px 58px;

	&:hover {
		background: rgb(227, 28, 61);
		color: #fff;
	}
}
.btn-projects {
	color: #00bee9;
	margin: 0 0 16px;
	padding-left: 22px;

	&.focus, &:focus, &:hover {
		color: #e31c3d;
	}
	&:before {
		content: '\2013';
		left: 24px;
		position: absolute;
	}
	&.collapsed:before {
		content: '+';
	}
}
.bg-outline {
	border: 1px solid #fff;
	display: inline-block;
	margin: 0 0 35px;
	padding: 15px;
}

@media print {
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
		float: left;
	}
	@for $i from 1 through 12 {
		.col-sm-#{$i} {
			width: #{percentage(round($i*8.33)/100)};
			float: left;
		}
	}
}